.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
}

.accordion-header.active {
  display: none;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.header-icon {
  font-size: 1.5em;
}

.accordion-content {
  display: none;
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

.accordion-content.show {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

@media screen and (max-width: 836px) {
  .accordion-content.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
}
