.border-container {
  position: relative;
  width: 342px;
  height: 248px;
  margin: 20px;
  border: 1px solid transparent;
}

@media screen and (max-width: 700) {
  .border-container {
    width: 100%;
  }
}

.border-corner {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 6px solid black;
}

.top-left {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.bottom-left {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.bottom-right {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.external-border {
  position: absolute;
  width: 70px;
  height: 70px;
  border: 6px solid green;
  animation: moveBorder 4s infinite;
}

@keyframes moveBorder {
  0% { top: 0; left: 0; }
  25% { top: 0; left: calc(100% - 70px); }
  50% { top: calc(100% - 70px); left: calc(100% - 70px); }
  75% { top: calc(100% - 70px); left: 0; }
  100% { top: 0; left: 0; }
}

.stop-animation {
  animation: none;
  border: none;
}
