.homepageHero {
  background: url("./images/here_sectionBG.png") no-repeat center center;
  background-size: cover;
}

.homepageHero2 {
  background: url("./images/here_sectionBG.png") no-repeat center center;
  background-size: cover;
}

.heading {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
  padding-bottom: 14%;
}

.image-container {
  width: 100%;
}

.image-container img {
  width: 100%;
  border-radius: 24px;
}

@media (min-width: 768px) {
  .image-container {
    width: auto;
  }
}

.linearGradient {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  padding: 13px 35px;
}

.linearGradient2 {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

.linear_gradient_btn {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

.linearColor {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

@media (max-width: 768px) {
  .my-class {
    max-width: 20rem; /* 320px */
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.icon {
  cursor: pointer;
  color: #9d27b0;
}

.days {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.row {
  display: flex;
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 2px;
}

.col-center {
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
}

.number {
  z-index: 1;
  color: #4a4a4a;
}

.bg {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected .bg {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
}

.disabled .number {
  color: #d3d3d3;
}

.col:hover {
  background-color: rgba(157, 39, 176, 0.1);
  cursor: pointer;
}

.yscrollbar::-webkit-scrollbar {
  background-color: #590064;
  width: 11px;
}

.yscrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  border-radius: 4px;
}