.payment-form {
  max-width: 400px;
  margin: auto;
}

.payment-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.payment-option {
  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0px 1rem;
  cursor: pointer;
  visibility: hidden;
}

.payment-option.active {
  background-color: #fff;
  border-color: #0070f3;
}

input, select, .StripeElement {
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 1rem;
}

.submit-button {
  background-color: #0070f3;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #cccccc;
}
