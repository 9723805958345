.custom-video {
  width: 100%;
  max-width: 640px;
  outline: none;
}

video::-webkit-media-controls-volume-slider {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  padding-top: 0;
  margin-top: 20px;
  padding-bottom: 0;
}

/* video::-webkit-media-controls-timeline {
  background: linear-gradient(89.1deg, #E20BE3 -2.04%, #7C067D 99.23%);
  padding-bottom: 0;
  margin-bottom: 20px;
} */
